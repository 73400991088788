import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import UserLogin from './components/UserLogin/UserLogin';
import Users from './components/Users/Users';
import AccountTypes from './components/Users/AccountTypes/AccountTypes';
import ChampionshipTabs from './components/Users/ChampionshipTabs/ChampionshipTabs';
import Pitches from './components/Users/Pitches/Pitches';
import Positions from './components/Users/Positions/Positions';
import StrongFootOptions from './components/Users/StrongFootOptions/StrongFootOptions';
import TeamRosterTypes from './components/Users/TeamRosterTypes/TeamRosterTypes';
import Reports from './components/Reports/Reports';
import BannedUsers from './components/Reports/BannedUsers/BannedUsers';
import BanReasons from './components/Reports/BanReasons/BanReasons';
import Cities from './components/Localization/Cities/Cities';
import Countries from './components/Localization/Countries/Countries';
import Regions from './components/Localization/Regions/Regions';
import SocialLinkTypes from './components/Settings/SocialLinkTypes/SocialLinkTypes';
import Genders from './components/Settings/Genders/Genders';
import AppSettings from './components/Settings/AppSettings/AppSettings';
import TestGrounds from './components/TestGrounds/TestGrounds';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App/>}>
        <Route path="/users" element={<Users/>} />
        <Route path="/users/account-types" element={<AccountTypes/>} />
        <Route path="/users/championshipTabs" element={<ChampionshipTabs/>} />
        <Route path="/users/pitches" element={<Pitches/>} />
        <Route path="/users/positions" element={<Positions/>} />
        <Route path="/users/strong-foot-options" element={<StrongFootOptions/>} />
        <Route path="/users/team-roster-types" element={<TeamRosterTypes/>} />
        <Route path="/reports" element={<Reports/>} />
        <Route path="/reports/bannedUsers" element={<BannedUsers/>} />
        <Route path="/reports/ban-reasons" element={<BanReasons/>} />
        <Route path="/localization/cities" element={<Cities/>} />
        <Route path="/localization/countries" element={<Countries/>} />
        <Route path="/localization/regions" element={<Regions/>} />
        <Route path="/settings/social-link-types" element={<SocialLinkTypes/>} />
        <Route path="/settings/genders" element={<Genders/>} />
        <Route path="/settings/app-settings" element={<AppSettings/>} />
        <Route path="/test-grounds" element={<TestGrounds/>} />
      </Route>
      <Route path="/login" element={<UserLogin/>} />
      
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
