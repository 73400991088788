import logo from './logo.svg';
import './App.css';
import {useState, useEffect} from 'react';
import UserLogin from './components/UserLogin/UserLogin'
import {useCookies} from 'react-cookie';
import { Navigate, useNavigate } from "react-router-dom";
import axios from 'axios';
import Header from './components/Layout/Header';




function App() {
  const [cookies, setCookies, removeCookies] = useCookies();
  const navigate = useNavigate();
  const [authenticationStatus, setAuthenticationStatus] = useState('Authenticating...')
  const [error, setError] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(()=>{
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.athlopro.gr/v1/verify',
      headers: { 
        'Accept': 'application/json', 
        'Authorization': 'Bearer '+cookies.access_token, 
      }
    };
  axios.request(config)
    .then((response) => {
      if (!response.data.status||response.data.isAdmin===0){
        removeCookies('access_token');
        navigate('/login');
      }else{
        setAuthenticationStatus('Authentication completed successfully!');
        setLoggedIn(true);
      }
    })
    .catch((error) => {
      console.log(error);
    });

    
  },[cookies]);

  return (
      <div className="App">
        {(!error && loggedIn) ? (
          <Header/>
        ) : (
          <p>{error}</p>
        )}
      </div>
    );
}

export default App;
