import React, {useState, useEffect, Fragment} from 'react';
import {useCookies} from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faSave, faUndo, faTrash } from '@fortawesome/free-solid-svg-icons'
import classes from './SingleGender.module.css'
import ConfirmationModal from '../../UI/ConfirmationModal'
import axios from 'axios';

const pencil = <FontAwesomeIcon icon={faPencil} />
const save = <FontAwesomeIcon icon={faSave} />
const undo = <FontAwesomeIcon icon={faUndo} />
const trash = <FontAwesomeIcon icon={faTrash} />

const SingleGender = ({ gender, startEditing, finishEditing, currentEdit, editingSibling }) => {
  const [cookies] = useCookies(['access_token']);
  const [isEditing, setIsEditing] = useState(false);
  const [editedFields, setEditedFields] = useState(gender);
  const [shownValues, setShownValues] = useState(gender);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const handleEditClick = () => {
    startEditing(gender.id);
    setIsEditing(!isEditing);
    setEditedFields({
      name: shownValues.name,
    });
  };


  const handleSaveClick = () => {
    //send only changed fields
    const changedFields = {};

    Object.keys(editedFields).forEach((field) => {
      if (editedFields[field] !== shownValues[field]) {
        changedFields[field] = editedFields[field];
      }
    });

    let data = {id: shownValues.id};
    if (Object.keys(changedFields).length > 0) {
      data = {
        id: shownValues.id,
        ...changedFields,
      };
    };
      

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.athlopro.gr/v1/admin/addOrUpdateGender',
      headers: { 
        'Accept': 'application/json', 
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+cookies.access_token
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      setShownValues(response.data.gender);
      setIsEditing(false);
      finishEditing(gender.id);
    })
    .catch((error) => {
      console.log(error);
    });

  };

  const handleUndoClick = () => {
      setIsEditing(false);
      finishEditing(gender.id);
      setEditedFields(shownValues);
  };

  const handleFieldChange = (field, value) => {
    if (field === 'name') {
      value = value.slice(0, 255); 
    }
    setEditedFields((prevFields) => ({ ...prevFields, [field]: value }));
  };

  const handleDeleteClick = () => {
    setIsModalOpen(true);
  }
  const handleConfirmDelete = () => {
    let data = {
      id: shownValues.id,
      delete: true,
    };

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.athlopro.gr/v1/admin/addOrUpdateGender',
      headers: { 
        'Accept': 'application/json', 
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+cookies.access_token
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      setShownValues(response.data.account_type);
      setIsModalOpen(false);
      setDeleted(true);
    })
    .catch((error) => {
      console.log(error);
    });  
  }

  const handleCancelDelete = () =>{
    setIsModalOpen(false);  
  }
  return (
    <Fragment>
    {!deleted ? (
    <tr key={gender.id}>
    <ConfirmationModal
        show={isModalOpen}
        onNo={handleCancelDelete}
        onYes={handleConfirmDelete}
      />
      <td>
        {currentEdit ? (
          <input
            type="text"
            value={editedFields.name}
            onChange={(e) => handleFieldChange('name', e.target.value)}
            style={{ width: '100%' }}
          />
        ) : (
          shownValues.name
        )}
      </td>
      <td>
        {currentEdit ? (
          <div className={classes.buttonSet}>
            <button onClick={handleSaveClick}>{save}</button>
            <button onClick={handleUndoClick}>{undo}</button>
          </div>
        ) : (
          <div className={classes.buttonSet} disabled={!currentEdit && editingSibling}>
            <button onClick={handleEditClick} disabled={!currentEdit && editingSibling}>{pencil}</button>
            <button onClick={handleDeleteClick} disabled={!currentEdit && editingSibling}>{trash}</button>
          </div>
        )}
      </td>
    </tr>
    ) : (
    <Fragment></Fragment>
    )}
    </Fragment>
  );
};

export default SingleGender;

