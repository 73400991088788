import React, {useState, useEffect, Fragment} from 'react';
import {useCookies} from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faSave, faUndo, faTrash } from '@fortawesome/free-solid-svg-icons'
import classes from './SingleRegion.module.css'
import ConfirmationModal from '../../UI/ConfirmationModal'
import axios from 'axios';

const pencil = <FontAwesomeIcon icon={faPencil} />
const save = <FontAwesomeIcon icon={faSave} />
const undo = <FontAwesomeIcon icon={faUndo} />
const trash = <FontAwesomeIcon icon={faTrash} />

const SingleRegion = ({ region, countriesList, startEditing, finishEditing, currentEdit, editingSibling }) => {
const [cookies] = useCookies(['access_token']);
const [isEditing, setIsEditing] = useState(false);
const [editedFields, setEditedFields] = useState(region);
const [shownValues, setShownValues] = useState(region);
const [isModalOpen, setIsModalOpen] = useState(false);
const [deleted, setDeleted] = useState(false);

const handleEditClick = () => {
  startEditing(region.id);
  setIsEditing(!isEditing);
  setEditedFields({
    name: shownValues.name,
    slug: shownValues.slug,
    country_id: shownValues.country_id,
    country: shownValues.country,
    active: shownValues.active,
  });
};


const handleSaveClick = () => {
  //send only changed fields
  const changedFields = {};

  Object.keys(editedFields).forEach((field) => {
    if (editedFields[field] !== shownValues[field]) {
      changedFields[field] = editedFields[field];
    }
  });

  let data = {id: shownValues.id};
  if (Object.keys(changedFields).length > 0) {
    data = {
      id: shownValues.id,
      ...changedFields,
    };
  };
    

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://api.athlopro.gr/v1/admin/addOrUpdateRegion',
    headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json', 
      'Authorization': 'Bearer '+cookies.access_token
    },
    data : data
  };

  axios.request(config)
  .then((response) => {
    setShownValues(response.data.region);
    finishEditing(region.id);
    setIsEditing(false);
  })
  .catch((error) => {
    console.log(error);
  });

};

const handleUndoClick = () => {
  finishEditing(region.id);
    setIsEditing(false);
    setEditedFields(shownValues);
};

const handleFieldChange = (field, value) => {
  if (field === 'name') {
    value = value.slice(0, 255); 
  }
  setEditedFields((prevFields) => ({ ...prevFields, [field]: value }));
};

const handleDeleteClick = () => {
    setIsModalOpen(true);
  }
  const handleConfirmDelete = () => {
    let data = {
      id: shownValues.id,
      delete: true,
    };

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.athlopro.gr/v1/admin/addOrUpdateRegion',
      headers: { 
        'Accept': 'application/json', 
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+cookies.access_token
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      setShownValues(response.data.account_type);
      setIsModalOpen(false);
      setDeleted(true);
    })
    .catch((error) => {
      console.log(error);
    });  
  }

  const handleCancelDelete = () =>{
    setIsModalOpen(false);  
  }
  return (
    <Fragment>
    {!deleted ? (
    <tr key={region.id}>
    <ConfirmationModal
        show={isModalOpen}
        onNo={handleCancelDelete}
        onYes={handleConfirmDelete}
      />
      <td>
        {shownValues.name}
      </td>
      <td>
        {shownValues.slug}
      </td>
      <td>
        {currentEdit ? (
          <select value={editedFields.country_id} onChange={(e) => handleFieldChange('country_id', Number(e.target.value))}>
          {countriesList.map((country) => (
            <option key={country.id} value={country.id}>
              {`${country.name}`}
            </option>
          ))}
          </select>
        ) : (
          `${shownValues.country}`
        )}
      </td>
      <td>
        {currentEdit ? (
          <input
            type="checkbox"
            checked={editedFields.active === 1}
            onChange={(e) => handleFieldChange('active', e.target.checked ? 1 : 0)}
          />
        ) : (
          shownValues.active===1?"Yes":"No"
        )}
      </td>
      <td>
        {currentEdit ? (
          <div className={classes.buttonSet}>
            <button onClick={handleSaveClick}>{save}</button>
            <button onClick={handleUndoClick}>{undo}</button>
          </div>
        ) : (
          <div className={classes.buttonSet} disabled={!currentEdit && editingSibling}>
            <button onClick={handleEditClick} disabled={!currentEdit && editingSibling}>{pencil}</button>
            <button onClick={handleDeleteClick} disabled={!currentEdit && editingSibling}>{trash}</button>
          </div>
        )}
      </td>
    </tr>
    ) : (
    <Fragment></Fragment>
    )}
    </Fragment>
  );
};

export default SingleRegion;