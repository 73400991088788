import React,{Fragment, useState, useEffect} from 'react';
import {useCookies} from 'react-cookie';
import { Container, Form, Row, Col, Pagination } from 'react-bootstrap';
import SingleBanReason from './SingleBanReason';
import AddModal from '../../UI/AddModal';
import axios from 'axios';

const BanReasons = (props) => {
	const [cookies] = useCookies(['access_token']);
	const [banReasons, setBanReasons] = useState([]);
	const [editingId, setEditingId] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
  	const [totalPages, setTotalPages] = useState(1);
  	const [perPage, setPerPage] = useState(25);
  	const [newEntry, setNewEntry] = useState(false);
  	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	useEffect(()=>{
		let data = {
		  "perPage": perPage,
		  "page": currentPage,
		};
		let config = {
		  method: 'post',
		  maxBodyLength: Infinity,
		  url: 'https://api.athlopro.gr/v1/admin/banReasons',
		  headers: { 
		    'Accept': 'application/json', 
		    'Authorization': 'Bearer '+ cookies.access_token,
		  },
		  data : data
		};

		axios.request(config)
		.then((response) => {
			setBanReasons(response.data.reasons);
			setTotalPages(response.data.total_pages);
		})
		.catch((error) => {
		  	console.log(error);
		});
	},[currentPage, perPage, newEntry])

	const handlePageChange = (newPage) => {
	    setCurrentPage(newPage);
	};

	const handlePerPageChange = (e) => {
    	setPerPage(e.target.value);
    	setCurrentPage(1); 
  	};

  const handleAddClick = () => {
  	setIsAddModalOpen(true);
  }

  const handleAddOnHide = () =>{
  	setIsAddModalOpen(false);
  }
  const handleAddOnSave = (input) =>{
  	let data = input;
  	let config = {
		  method: 'post',
		  maxBodyLength: Infinity,
		  url: 'https://api.athlopro.gr/v1/admin/addOrUpdateBanReason',
		  headers: { 
		    'Accept': 'application/json', 
		    'Content-Type': 'application/json', 
		    'Authorization': 'Bearer '+cookies.access_token
		  },
		  data : data
		};

		axios.request(config)
		.then((response) => {
			// console.log(response.data);
		  setNewEntry(!newEntry);
		  setIsAddModalOpen(false);
		})
		.catch((error) => {
		  console.log(error);
		});
   }

   const startEditing = (id) => {
	  setEditingId(id);
	};

	const finishEditing = () => {
	  setEditingId(null);
	};
	return( 
	<Container>
	    <Row>
	    	<AddModal
	        show={isAddModalOpen}
	        onHide={handleAddOnHide}
	        onSave={handleAddOnSave}
	        fieldNames={["reason:required"]}
	        selectLists={{}}
	        limits={[{name:'reason',maxLength:255,type:'string'}]}
	      	/>
			<Col>
    			<h2>Ban Reasons</h2>
    			<button onClick={handleAddClick} disabled={editingId!==null}>Add new +</button>
    		</Col>
    		<Col>
		    	<Form.Group controlId="perPageSelect" className="d-flex align-items-center perPageSelect justify-content-end">
		            <Form.Label>Show per page:</Form.Label>
		            <Form.Control as="select" value={perPage} onChange={handlePerPageChange}>
		              <option value={25}>25</option>
		              <option value={50}>50</option>
		              <option value={100}>100</option>
		              <option value={250}>250</option>
		              <option value={500}>500</option>
		            </Form.Control>
	        	</Form.Group>
       		</Col>
      	</Row>
	    <Row>
	        <Col>
	          <table className="table">
	            <thead>
	              <tr>
	                <th>Reason</th>
	                <th>Action</th>
	              </tr>
	            </thead>
	            <tbody>
	              {banReasons.map((banReason) => (
                	<SingleBanReason 
                	key={banReason.id} 
                	banReason={banReason}
                	currentEdit={editingId === banReason.id}
	                editingSibling={editingId!==null}
	                startEditing={startEditing}
	                finishEditing={finishEditing} />
             		))}
	            </tbody>
	          </table>
	        </Col>
	    </Row>
	    <Pagination>
	        <Pagination.Prev
	          onClick={() => handlePageChange(currentPage - 1)}
	          disabled={currentPage === 1}
	        />
	        <Pagination.Item>{currentPage}</Pagination.Item>
	        <Pagination.Next
	          onClick={() => handlePageChange(currentPage + 1)}
	          disabled={currentPage === totalPages}
	        />
      </Pagination>
	</Container>
	    )
}

export default BanReasons;