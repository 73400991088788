import React, {useState, useEffect, Fragment} from 'react';
import {useCookies} from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faSave, faUndo, faTrash } from '@fortawesome/free-solid-svg-icons'
import classes from './SingleBanned.module.css'
import ConfirmationModal from '../../UI/ConfirmationModal'
import axios from 'axios';

const pencil = <FontAwesomeIcon icon={faPencil} />
const save = <FontAwesomeIcon icon={faSave} />
const undo = <FontAwesomeIcon icon={faUndo} />
const trash = <FontAwesomeIcon icon={faTrash} />

const SingleBanned = ({ banned, reasonsList, startEditing, finishEditing, currentEdit, editingSibling }) => {
  const [cookies] = useCookies(['access_token']);
  const [isEditing, setIsEditing] = useState(false);
  const [editedFields, setEditedFields] = useState(banned);
  const [shownValues, setShownValues] = useState(banned);
  const [selectedReason, setSelectedReason] = useState(banned.reason.id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleted, setDeleted] = useState(false);



  const handleEditClick = () => {
    startEditing(banned.id);
    setIsEditing(!isEditing);
    setEditedFields({
      user: shownValues.user,
      reason: shownValues.reason,
    });
  };

  const handleSaveClick = () => {
    //send only changed fields
    const changedFields = {};

    let data = {
      id: shownValues.id,
      user_id: editedFields.user.id,
      reason_id: editedFields.reason.id,
    };


    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.athlopro.gr/v1/admin/ban',
      headers: { 
        'Accept': 'application/json', 
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+cookies.access_token
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      setShownValues(response.data.banned);
      console.log(response.data)
      setIsEditing(false);
      finishEditing(banned.id);
    })
    .catch((error) => {
      console.log(error);
    });
    
  };

  const handleUndoClick = () => {
      setIsEditing(false);
      finishEditing(banned.id);
      setEditedFields(shownValues);
  };
  
  const handleFieldChange = (parentField, nestedField, value) => {
    setEditedFields((prevFields) => ({
      ...prevFields,
      [parentField]: {
        ...prevFields[parentField],
        [nestedField]: value,
      },
    }));
  };

  const handleDeleteClick = () => {
    setIsModalOpen(true);
  }
  const handleConfirmDelete = () => {
    let data = {
      user_id: shownValues.user.id,
    };

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.athlopro.gr/v1/admin/ban',
      headers: { 
        'Accept': 'application/json', 
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+cookies.access_token
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      setShownValues(response.data.account_type);
      setIsModalOpen(false);
      setDeleted(true);
    })
    .catch((error) => {
      console.log(error);
    });  
  }

  const handleCancelDelete = () =>{
    setIsModalOpen(false);  
  }
  return (
    <Fragment>
    {!deleted ? (
    <tr key={banned.id}>
    <ConfirmationModal
        show={isModalOpen}
        onNo={handleCancelDelete}
        onYes={handleConfirmDelete}
      />
      <td>{banned.user.first_name} {banned.user.last_name}</td>
      <td>
        {currentEdit ? (
          <select value={editedFields.reason.id} onChange={(e) => handleFieldChange('reason','id', e.target.value)}>
          {reasonsList.map((reason) => (
            <option key={reason.id} value={reason.id}>
              {`${reason.reason}`}
            </option>
          ))}
          </select>
        ) : (
          `${shownValues.reason.reason}`
        )}
      </td>
      {/*<td>{banned.reason.id} - {banned.reason.reason}</td>*/}
      <td>
        {currentEdit ? (
          <div className={classes.buttonSet}>
            <button onClick={handleSaveClick}>{save}</button>
            <button onClick={handleUndoClick}>{undo}</button>
          </div>
        ) : (
          <div className={classes.buttonSet} disabled={!currentEdit && editingSibling}>
            <button onClick={handleEditClick} disabled={!currentEdit && editingSibling}>{pencil}</button>
            <button onClick={handleDeleteClick} disabled={!currentEdit && editingSibling}>{trash}</button>
          </div>
        )}
      </td>
    </tr>
    ) : (
    <Fragment></Fragment>
    )}
    </Fragment>
  );
};

export default SingleBanned;