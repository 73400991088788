import React, { useState,useEffect } from 'react';

import { initializeApp } from 'firebase/app';
// import { getFirestore, collection, getDocs } from 'firebase/firestore'; 
import { getMessaging, getToken } from 'firebase/messaging';

const TestGrounds = () => {

	const [data, setData] = useState([]);

	// useEffect(() => {



	// 	const db = getFirestore(firebaseApp);

	  
	//     const fetchData = async () => {
	//           try {
	//             const snapshot = await getDocs(collection(db, 'testCollection'));
	//             const newData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
	//             setData(newData);
	//           } catch (error) {
	//             console.error('Error fetching data:', error);
	//           }
	//         };

	//         fetchData();
	//   }, []);

	useEffect(() => {
		const firebaseConfig = {
		  apiKey: "AIzaSyALxIom-A7SuyuNhXm5eCwEzC9yLPeY3NY",
		  authDomain: "https://www.googleapis.com/oauth2/v1/certs",
		  projectId: "athlopro-app",
		  storageBucket: "athlopro-app.appspot.com",
		  messagingSenderId: "829006890899",
		  appId: "1:829006890899:android:e12fc11fae0f5d1182ca66"
		};
		const app = initializeApp(firebaseConfig);

		// Get Firebase Messaging instance
		const messaging = getMessaging(app);

		// Function to request notification permission
		const requestNotificationPermission = async () => {
		  try {
		    // Request permission
		    const permission = await Notification.requestPermission();
		    if (permission === 'granted') {
		      // Permission granted, retrieve token
		      const currentToken = await getToken(messaging);
		      if (currentToken) {
		        console.log('Device token:', currentToken);
		      } else {
		        console.log('No device token available.');
		      }
		    }
		  } catch (error) {
		    console.error('Error requesting notification permission:', error);
		  }
		};

		// Event handler for button click
		const handleClick = () => {
		  requestNotificationPermission();
		};

		// Attach event handler to button
		const button = document.getElementById('notification-button');
		if (button) {
		  button.addEventListener('click', handleClick);
		}

		// Clean up event listener on component unmount
		return () => {
		  if (button) {
		    button.removeEventListener('click', handleClick);
		  }
		};
	  }, []);

	  return (
	    <div>
	      <button id="notification-button">Request Notification Permission</button>
	    </div>
	  );
};

export default TestGrounds;