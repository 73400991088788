import React,{Fragment, useState} from 'react';
import {useCookies} from 'react-cookie';
import { Dropdown } from 'react-bootstrap';
import { Outlet, Link } from "react-router-dom";
import axios from 'axios';



import classes from './Header.module.css';
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState({users:false,reports:false, localization:false ,settings:false});
  const [cookies, setCookie, removeCookie] = useCookies(['access_token']);

  const handleMouseEnter = (dropdown) => {
    setIsOpen((prevState) => ({
      ...Object.fromEntries(Object.keys(prevState).map(key => [key, key === dropdown])),
    }));
  };

  const handleMouseLeave = (dropdown) => {
    setTimeout(() => {
      setIsOpen(prevState => ({
          ...prevState,
          [dropdown]: false,
        }));
    }, 400);
  };

  const handleLogout = (e) => {
    e.preventDefault();

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.athlopro.gr/v1/logout',
      headers: { 
        'Accept': 'application/json', 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.access_token}`
      },
      data: {}
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data.status) {
          removeCookie('access_token', { sameSite: 'None', secure: true });
          navigate('/login');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Fragment>
      <header className={classes.header}>
        <Link to="/" className={classes.menuItem}>Dashboard</Link>
        <Dropdown show={isOpen.users} onMouseEnter={()=>handleMouseEnter('users')} onMouseLeave={()=>handleMouseLeave('users')}>
          <Dropdown.Toggle  className={classes.dropdownToggle} id="users-dropdown">
            <Link to="users" className={classes.menuItem}>Users</Link>
          </Dropdown.Toggle>
          <Dropdown.Menu onMouseEnter={()=>handleMouseEnter('users')} onMouseLeave={()=>handleMouseLeave('users')}>
              <Dropdown.Item href="/users/account-types">Account Types</Dropdown.Item>
              <Dropdown.Item href="/users/championshipTabs">Championships</Dropdown.Item>         
              <Dropdown.Item href="/users/pitches">Pitches</Dropdown.Item>
              <Dropdown.Item href="/users/positions">Positions</Dropdown.Item>
              <Dropdown.Item href="/users/strong-foot-options">Strong Foot Options</Dropdown.Item>
              <Dropdown.Item href="/users/team-roster-types">Team Roster Types</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>  
        <Dropdown show={isOpen.reports} onMouseEnter={()=>handleMouseEnter('reports')} onMouseLeave={()=>handleMouseLeave('reports')}>
          <Dropdown.Toggle  className={classes.dropdownToggle} id="reports-dropdown">
            <Link to="reports" className={classes.menuItem}>Reports</Link>
          </Dropdown.Toggle>
          <Dropdown.Menu onMouseEnter={()=>handleMouseEnter('reports')} onMouseLeave={()=>handleMouseLeave('reports')}>
            <Dropdown.Item href="/reports/bannedUsers">Banned Users</Dropdown.Item>
            <Dropdown.Item href="/reports/ban-reasons">Ban Reasons</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown show={isOpen.localization} onMouseEnter={()=>handleMouseEnter('localization')} onMouseLeave={()=>handleMouseLeave('localization')}>
          <Dropdown.Toggle  className={classes.dropdownToggle} id="localization-dropdown">
            <Link to="#" className={classes.menuItem}>Localization</Link>
          </Dropdown.Toggle>
          <Dropdown.Menu onMouseEnter={()=>handleMouseEnter('localization')} onMouseLeave={()=>handleMouseLeave('localization')}>
            <Dropdown.Item href="/localization/countries">Countries</Dropdown.Item>
            <Dropdown.Item href="/localization/regions">Regions</Dropdown.Item>
            <Dropdown.Item href="/localization/cities">Cities</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>   
      <Dropdown show={isOpen.settings} onMouseEnter={()=>handleMouseEnter('settings')} onMouseLeave={()=>handleMouseLeave('settings')}>
        <Dropdown.Toggle  className={classes.dropdownToggle} id="localization-dropdown">
          <Link to="#" className={classes.menuItem}>General Settings</Link>
        </Dropdown.Toggle>
        <Dropdown.Menu onMouseEnter={()=>handleMouseEnter('settings')} onMouseLeave={()=>handleMouseLeave('settings')}>
          <Dropdown.Item href="/settings/social-link-types">Social Link Types</Dropdown.Item>
          <Dropdown.Item href="/settings/genders">Genders</Dropdown.Item>
          <Dropdown.Item href="/settings/app-settings">App Settings</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> 
      <button className={classes.logoutButton} onClick={handleLogout}>Logout</button>
      </header>
      <main style={{ marginTop: '100px' }}>
      <Outlet/>
      </main>
    </Fragment>
  );
};

export default Header;
