import React, {useState, Fragment} from 'react';
import {useCookies} from 'react-cookie';
import classes from './SingleReport.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import ConfirmationModal from '../UI/ConfirmationModal'
import axios from 'axios';

const trash = <FontAwesomeIcon icon={faTrash} />

const SingleReport = ({ report }) => {
  const [cookies] = useCookies(['access_token']);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const handleDeleteClick = () => {
    setIsModalOpen(true);
  }
  const handleConfirmDelete = () => {
    let data = {
      id: report.id,
      delete: true,
    };

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.athlopro.gr/v1/admin/editReport',
      headers: { 
        'Accept': 'application/json', 
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+cookies.access_token
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      setIsModalOpen(false);
      setDeleted(true);
    })
    .catch((error) => {
      console.log(error);
    });  
  }

  const handleCancelDelete = () =>{
    setIsModalOpen(false);  
  }

  return (
    <Fragment>
    {!deleted ? (
    <tr key={report.id}>
    <ConfirmationModal
      show={isModalOpen}
      onNo={handleCancelDelete}
      onYes={handleConfirmDelete}
    />
      <td>{report.user}</td>
      <td>{report.report_user}</td>
      <td>{report.reason}</td>
      <td>{report.description}</td>
      <td>
        <div className={classes.buttonSet}>
          <button onClick={handleDeleteClick}>{trash}</button>
        </div>
      </td>
    </tr>
    ) : (
    <Fragment></Fragment>
    )}
    </Fragment>
  );
};

export default SingleReport;