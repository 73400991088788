import React, {useState, useEffect} from 'react';
import {useCookies} from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faSave, faUndo } from '@fortawesome/free-solid-svg-icons'
import classes from './SingleAppSetting.module.css'
import axios from 'axios';

const pencil = <FontAwesomeIcon icon={faPencil} />
const save = <FontAwesomeIcon icon={faSave} />
const undo = <FontAwesomeIcon icon={faUndo} />

const SingleAppSetting = ({ setting, startEditing, finishEditing, currentEdit, editingSibling }) => {
  const [cookies] = useCookies(['access_token']);
  const [isEditing, setIsEditing] = useState(false);
  const [editedFields, setEditedFields] = useState(setting);
  const [shownValues, setShownValues] = useState(setting);
  const [gradient, setGradient] = useState([])
  useEffect(()=>{
    if (setting.type==="gradient"){
      setGradient(JSON.parse(setting.value));
    }
  },[])
  const handleEditClick = () => {
    startEditing(setting.id);
    setIsEditing(!isEditing);
    setEditedFields({
      value: shownValues.value,
    });
  };


  const handleSaveClick = () => {
    //send only changed fields
    const changedFields = {};

    Object.keys(editedFields).forEach((field) => {
      if (editedFields[field] !== shownValues[field]) {
        changedFields[field] = editedFields[field];
      }
    });

    let data = {id: shownValues.id};
    if (Object.keys(changedFields).length > 0) {
      data = {
        id: shownValues.id,
        ...changedFields,
      };
    };
      

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.athlopro.gr/v1/admin/editSetting',
      headers: { 
        'Accept': 'application/json', 
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+cookies.access_token
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      setShownValues(response.data.setting);
      setIsEditing(false);
      finishEditing(setting.id);
    })
    .catch((error) => {
      console.log(error);
    });

  };

  const handleUndoClick = () => {
    finishEditing(setting.id);
      setIsEditing(false);
      setEditedFields(shownValues);
  };

  const handleFieldChange = (field, value) => {
    if (field === 'value' && setting.type === 'text') {
      value = value.slice(0, 255); 
    }
    setEditedFields((prevFields) => ({ ...prevFields, [field]: value }));
  };

  const handleGradientChange = (field, value) =>{
    setGradient((prevFields) => ({ ...prevFields, [field]: value }));
  }

  useEffect(()=>{
    if (setting.type==="gradient"){ 
      handleFieldChange('value', JSON.stringify(gradient));
    }
  },[gradient])
  return (
    <tr key={setting.id}>
      <td>{setting.key}</td>
      <td>
        {currentEdit ? (
          setting.type === "boolean" ? (
            <input
              type="checkbox"
              checked={editedFields.value === "1"}
              onChange={(e) => handleFieldChange('value', e.target.checked ? "1" : "0")}
            />
          ) : setting.type === "color" ? (
            <input
              type="color"
              value={editedFields.value}
              onChange={(e) => handleFieldChange('value', e.target.value)}
            />
          ) : setting.type === "gradient" ? (
            <div>
            {editedFields.value &&
              Object.values(JSON.parse(editedFields.value)).map((color, index) => (
              <input
                type="color"
                value={color}
                style={{marginRight:'10px'}}
                onChange={(e) => handleGradientChange(`color_${index+1}`, e.target.value)}
              />
              ))}
            </div>
          ) : (
            <input
              type="text"
              value={editedFields.value}
              onChange={(e) => handleFieldChange('value', e.target.value)}
              style={{ width: '100%' }}
            />
          )
        ) : (
          setting.type === "color" ? (
            <input
              type="color"
              value={editedFields.value}
              readOnly
              style={{pointerEvents: 'none'}}
            />
          ) : setting.type === 'gradient' ? (
          <div>
            {shownValues.value &&
              Object.values(JSON.parse(shownValues.value)).map((color) => (
              <input
                type="color"
                value={color}
                readOnly
                style={{pointerEvents: 'none',marginRight:'10px'}}
              />
              ))}
          </div>
        ) : (
            shownValues.value
          )
          )}
      </td>
      {setting.type!=="static" ? (<td>
        {currentEdit ? (
          <div className={classes.buttonSet}>
            <button onClick={handleSaveClick}>{save}</button>
            <button onClick={handleUndoClick}>{undo}</button>
          </div>
        ) : (
          <div className={classes.buttonSet} disabled={!currentEdit && editingSibling}>
            <button onClick={handleEditClick} disabled={!currentEdit && editingSibling}>{pencil}</button>
          </div>
        )}
      </td> ) : (
      <td>
      </td>
      )}
    </tr>
  );
};

export default SingleAppSetting;

