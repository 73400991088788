import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faUndo } from '@fortawesome/free-solid-svg-icons'
import classes from './AddModal.module.css';

const save = <FontAwesomeIcon icon={faSave} />
const undo = <FontAwesomeIcon icon={faUndo} />

const AddModal = ({ show, onHide, fieldNames, onSave, selectLists, limits }) => {
  const [inputValues, setInputValues] = useState({});
  const [modalField, setModalField] = useState({});
  const handleInputChange = (fieldName, value) => {
    const appliedLimit = limits.find((limit) => limit.name === fieldName.replace(/([^:]+):.*/, '$1'));
    if (appliedLimit) {
      const { maxLength ,type } = appliedLimit;
      if (maxLength && value.length > maxLength) {
        value = value.slice(0, maxLength);
      }
      if (type==='integer'){
        value = parseInt(value) || '';
      }
    }
    setInputValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };
  const handleSaveClick = () => {
    let saveValues = Object.entries(inputValues).reduce((acc, [fieldName, value]) => {

    let modifiedFieldName = fieldName.replace(/:required$/, '');

    acc[modifiedFieldName] = value;

    return acc;
  }, {});
    onSave(saveValues);
    setInputValues({}); 
  };

  return (
      <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add new</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {fieldNames.map((fieldName) => (
        <Row key={fieldName} className="mb-3">
        {fieldName.replace(/:required$/, '') === "active"?
        (<Fragment>
          <Col>
            <label htmlFor={fieldName}>{fieldName.charAt(0).toUpperCase() + fieldName.slice(1).replace(/([^:]+):.*/, '$1*').replace(/_/g, ' ')}</label>
          </Col>
          <Col>
            <input
              type="checkbox"
              checked={inputValues[fieldName] === 1}
              onChange={(e) => handleInputChange(fieldName, e.target.checked ? 1 : 0)}
            />
          </Col>
        </Fragment>
        ) : (
        <Fragment>
          {!fieldName.replace(/:required$/, '').endsWith("_id")?
        (
          <Fragment>
          <Col>
            <label htmlFor={fieldName}>{fieldName.charAt(0).toUpperCase() + fieldName.slice(1).replace(/([^:]+):.*/, '$1*').replace(/_/g, ' ')}</label>
          </Col>
          <Col>
            <input
              type="text"
              id={fieldName}
              value={inputValues[fieldName] || ''}
              onChange={(e) => handleInputChange(fieldName, e.target.value)}
            />
          </Col>
          <Col>
            <span className={classes.hint}>{'<='} {limits.find((limit) => limit.name === fieldName.replace(/([^:]+):.*/, '$1')).maxLength} {(limits.find((limit) => limit.name === fieldName.replace(/([^:]+):.*/, '$1')).type)==='integer'?'numbers':'characters'}</span>
          </Col>
          </Fragment>
          ) : (
          <Fragment>
          <Col>
            <label htmlFor={fieldName}>{fieldName.charAt(0).toUpperCase() + fieldName.slice(1).replace(/([^:]+):.*/, '$1*').replace(/_/g, ' ')}</label>
          </Col>
          <Col>
            
            {(() => {
              const fieldIndex = fieldName.replace(/_id?.*/, '');
              return (
                <select value={inputValues[fieldName]} onChange={(e) => handleInputChange(fieldName, e.target.value)}>
                  <option value="">Select an option</option>
                  {selectLists[fieldIndex]?.map((item) => (
                    <option key={item.id} value={parseInt(item.id)}>
                      {item[fieldIndex]?
                        (
                        <Fragment>
                          {`${item[fieldIndex]}`}
                        </Fragment>
                        ) : (
                        <Fragment>
                          {`${item['name']}`}
                        </Fragment>
                        )
                      }
                      
                    </option>
                  ))}
                </select>
              );
            })()}
            
          </Col>
          </Fragment>
          )} 
        </Fragment>
        )
        }
        </Row>
      ))}
      </Modal.Body>
      <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>
        {undo}
      </Button>
      <Button variant="danger" onClick={handleSaveClick}>
        {save}
      </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddModal;