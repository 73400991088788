import React, {useState, Fragment} from 'react';
import {useCookies} from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faSave, faUndo, faTrash } from '@fortawesome/free-solid-svg-icons'
import classes from './SingleCountry.module.css'
import ConfirmationModal from '../../UI/ConfirmationModal'
import axios from 'axios';

const pencil = <FontAwesomeIcon icon={faPencil} />
const save = <FontAwesomeIcon icon={faSave} />
const undo = <FontAwesomeIcon icon={faUndo} />
const trash = <FontAwesomeIcon icon={faTrash} />

const SingleCountry = ({ country, startEditing, finishEditing, currentEdit, editingSibling }) => {
const [cookies] = useCookies(['access_token']);
const [isEditing, setIsEditing] = useState(false);
const [editedFields, setEditedFields] = useState(country);
const [shownValues, setShownValues] = useState(country);
const [isModalOpen, setIsModalOpen] = useState(false);
const [deleted, setDeleted] = useState(false);

const handleEditClick = () => {
  startEditing(country.id);
  setIsEditing(!isEditing);
  setEditedFields({
    name: shownValues.name,
    continent: shownValues.continent,
    alpha2: shownValues.alpha2,
    alpha3: shownValues.alpha3,
    num: shownValues.num,
    phone_code: shownValues.phone_code,
    currency_code: shownValues.currency_code,
    currency_number: shownValues.currency_number,
    active: shownValues.active,
  });
};

const handleSaveClick = () => {
  //send only changed fields
  const changedFields = {};

  Object.keys(editedFields).forEach((field) => {
    if (editedFields[field] !== shownValues[field]) {
      changedFields[field] = editedFields[field];
    }
  });
  let data = {id: shownValues.id};
  if (Object.keys(changedFields).length > 0) {
    data = {
      id: shownValues.id,
      ...changedFields,
    };
    console.log(data);
  };
    

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://api.athlopro.gr/v1/admin/addOrUpdateCountry',
    headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json', 
      'Authorization': 'Bearer '+cookies.access_token
    },
    data : data
  };

  axios.request(config)
  .then((response) => {
    setShownValues(response.data.country);
    finishEditing(country.id);
    setIsEditing(false);
  })
  .catch((error) => {
    console.log(error);
  });

};

const handleUndoClick = () => {
  finishEditing(country.id);
  setIsEditing(false);
  setEditedFields(shownValues);
};

const handleFieldChange = (field, value) => {
  if (field === 'alpha2') {
      value = value.slice(0, 2); 
    }
  if (field === 'alpha3' ) {
      value = value.slice(0, 3); 
    }
  if (field === 'name'|| field === 'continent') {
      value = value.slice(0, 255); 
    }  
  if (field === 'num'|| field === 'phone_code' || field === 'currency_number') {
      value = value.slice(0, 11).replace(/[^0-9]/g, '');; 
    } 
  setEditedFields((prevFields) => ({ ...prevFields, [field]: value }));
};

const handleDeleteClick = () => {
    setIsModalOpen(true);
  }
  const handleConfirmDelete = () => {
    let data = {
      id: shownValues.id,
      delete: true,
    };

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.athlopro.gr/v1/admin/addOrUpdateCountry',
      headers: { 
        'Accept': 'application/json', 
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+cookies.access_token
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      setShownValues(response.data.account_type);
      setIsModalOpen(false);
      setDeleted(true);
    })
    .catch((error) => {
      console.log(error);
    });  
  }

  const handleCancelDelete = () =>{
    setIsModalOpen(false);  
  }
  return (
    <Fragment>
    {!deleted ? (
    <tr key={country.id}>
    <ConfirmationModal
        show={isModalOpen}
        onNo={handleCancelDelete}
        onYes={handleConfirmDelete}
      />
      <td>
        {currentEdit ? (
          <input
            type="text"
            value={editedFields.name}
            onChange={(e) => handleFieldChange('name', e.target.value)}
            style={{ width: '100%' }}
          />
        ) : (
          shownValues.name
        )}
      </td>
      <td>
        {currentEdit ? (
          <input
            type="text"
            value={editedFields.continent}
            onChange={(e) => handleFieldChange('continent', e.target.value)}
            style={{ width: '100%' }}
          />
        ) : (
          shownValues.continent
        )}
      </td>
      <td>
        {currentEdit ? (
          <input
            type="text"
            value={editedFields.alpha2}
            onChange={(e) => handleFieldChange('alpha2', e.target.value)}
            style={{ width: '100%' }}
          />
        ) : (
          shownValues.alpha2
        )}
      </td>
      <td>
        {currentEdit ? (
          <input
            type="text"
            value={editedFields.alpha3}
            onChange={(e) => handleFieldChange('alpha3', e.target.value)}
            style={{ width: '100%' }}
          />
        ) : (
          shownValues.alpha3
        )}
      </td>
      <td>
        {currentEdit ? (
          <input
            type="text"
            value={editedFields.num}
            onChange={(e) => handleFieldChange('num', e.target.value)}
            style={{ width: '100%' }}
          />
        ) : (
          shownValues.num
        )}
      </td>
      <td>
        {currentEdit ? (
          <input
            type="text"
            value={editedFields.phone_code}
            onChange={(e) => handleFieldChange('phone_code', e.target.value)}
            style={{ width: '100%' }}
          />
        ) : (
          shownValues.phone_code
        )}
      </td>
      <td>
        {currentEdit ? (
          <input
            type="text"
            value={editedFields.currency_code}
            onChange={(e) => handleFieldChange('currency_code', e.target.value)}
            style={{ width: '100%' }}
          />
        ) : (
          shownValues.currency_code
        )}
      </td>
      <td>
        {currentEdit ? (
          <input
            type="text"
            value={editedFields.currency_number}
            onChange={(e) => handleFieldChange('currency_number', e.target.value)}
            style={{ width: '100%' }}
          />
        ) : (
          shownValues.currency_number
        )}
      </td>
      <td>
        {currentEdit ? (
          <input
            type="checkbox"
            checked={editedFields.active === 1}
            onChange={(e) => handleFieldChange('active', e.target.checked ? 1 : 0)}
          />
        ) : (
          shownValues.active===1?"Yes":"No"
        )}
      </td>
      <td>
        {currentEdit ? (
          <div className={classes.buttonSet}>
            <button onClick={handleSaveClick}>{save}</button>
            <button onClick={handleUndoClick}>{undo}</button>
          </div>
        ) : (
          <div className={classes.buttonSet} disabled={!currentEdit && editingSibling}>
            <button onClick={handleEditClick} disabled={!currentEdit && editingSibling}>{pencil}</button>
            <button onClick={handleDeleteClick} disabled={!currentEdit && editingSibling}>{trash}</button>
          </div>
        )}
      </td>
    </tr>
    ) : (
    <Fragment></Fragment>
    )}
    </Fragment>
  );
};

export default SingleCountry;

