import React, {useState, Fragment} from 'react';
import {useCookies} from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faSave, faUndo, faTrash } from '@fortawesome/free-solid-svg-icons'
import classes from './SingleChampionshipPlacement.module.css'
import ConfirmationModal from '../../UI/ConfirmationModal'
import axios from 'axios';

const pencil = <FontAwesomeIcon icon={faPencil} />
const save = <FontAwesomeIcon icon={faSave} />
const undo = <FontAwesomeIcon icon={faUndo} />
const trash = <FontAwesomeIcon icon={faTrash} />

const SingleChampionshipPlacement = ({ placement, startEditing, finishEditing, currentEdit, editingSibling }) => {
  const [cookies] = useCookies(['access_token']);
  const [isEditing, setIsEditing] = useState(false);
  const [editedFields, setEditedFields] = useState(placement);
  const [shownValues, setShownValues] = useState(placement);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const handleEditClick = () => {
    startEditing(placement.id);
    setIsEditing(!isEditing);
    setEditedFields({
      name: shownValues.name,
      active: shownValues.active,
    });
  };

  const handleSaveClick = () => {
    //send only changed fields
    const changedFields = {};

    Object.keys(editedFields).forEach((field) => {
      if (editedFields[field] !== shownValues[field]) {
        changedFields[field] = editedFields[field];
      }
    });
    let data = {id: shownValues.id};
    if (Object.keys(changedFields).length > 0) {
      data = {
        id: shownValues.id,
        ...changedFields,
      };
    };

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.athlopro.gr/v1/admin/editChampionshipPlacement',
        headers: { 
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+cookies.access_token
        },
        data : data
      };

      axios.request(config)
      .then((response) => {
        setShownValues(response.data.championship)
        setIsEditing(false);
        finishEditing(placement.id);
      })
      .catch((error) => {
        console.log(error);
      });

    };
    
    const handleUndoClick = () => {
        setIsEditing(false);
        finishEditing(placement.id);
        setEditedFields(shownValues);
    };
    
    const handleDeleteClick = () => {
    setIsModalOpen(true);
  }
  const handleConfirmDelete = () => {
    let data = {
      id: shownValues.id,
      delete: true,
    };

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.athlopro.gr/v1/admin/editChampionshipPlacement',
      headers: { 
        'Accept': 'application/json', 
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+cookies.access_token
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      setShownValues(response.data.championship);
      setIsModalOpen(false);
      setDeleted(true);
    })
    .catch((error) => {
      console.log(error);
    });  
  }

  const handleCancelDelete = () =>{
    setIsModalOpen(false);  
  }

    const handleFieldChange = (field, value) => {
      if (field === 'name') {
        value = value.slice(0, 255); 
      }
      setEditedFields((prevFields) => ({ ...prevFields, [field]: value }));
    };
  return (
    <Fragment>
    {!deleted ? (
    <tr key={placement.id}>
    <ConfirmationModal
        show={isModalOpen}
        onNo={handleCancelDelete}
        onYes={handleConfirmDelete}
      />
      <td>
        {currentEdit ? (
          <input
            type="text"
            value={editedFields.name}
            onChange={(e) => handleFieldChange('name', e.target.value)}
            style={{ width: '100%' }}
          />
        ) : (
          shownValues.name
        )}
      </td>
      <td>
        {currentEdit ? (
          <input
            type="checkbox"
            checked={editedFields.active === 1}
            onChange={(e) => handleFieldChange('active', e.target.checked ? 1 : 0)}
          />
        ) : (
          shownValues.active===1?"Yes":"No"
        )}
      </td>
      <td>
        {currentEdit ? (
          <div className={classes.buttonSet}>
            <button onClick={handleSaveClick}>{save}</button>
            <button onClick={handleUndoClick}>{undo}</button>
          </div>
        ) : (
          <div className={classes.buttonSet} disabled={!currentEdit && editingSibling}>
            <button onClick={handleEditClick} disabled={!currentEdit && editingSibling}>{pencil}</button>
            <button onClick={handleDeleteClick} disabled={!currentEdit && editingSibling}>{trash}</button>
          </div>
        )}
      </td>
    </tr>
    ) : (
    <Fragment></Fragment>
    )}
    </Fragment>
  );
};

export default SingleChampionshipPlacement;