import React,{Fragment, useState, useEffect} from 'react';
import {useCookies} from 'react-cookie';
import { Container, Form, Row, Col, Pagination } from 'react-bootstrap';
import SingleBanned from './SingleBanned';
import AddModal from '../../UI/AddModal';
import axios from 'axios';

const Banned = (props) => {
	const [cookies] = useCookies(['access_token']);
	const [bannedUsers, setBannedUsers] = useState([]);
	const [editingId, setEditingId] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [perPage, setPerPage] = useState(25);
	const [reasonsList, setReasonsList] = useState([]);
	const [usersList, setUsersList] = useState([]);
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);

	useEffect(()=>{

		let data = JSON.stringify({
		  "perPage": 1000,
		  "page": 1
		});

		let config = {
		  method: 'post',
		  maxBodyLength: Infinity,
		  url: 'https://api.athlopro.gr/v1/admin/banReasons',
		  headers: { 
		    'Accept': 'application/json', 
		    'Content-Type': 'application/json', 
		    'Authorization': 'Bearer '+cookies.access_token
		  },
		  data : data
		};

		axios.request(config)
		.then((response) => {
		  setReasonsList(response.data.reasons);
		  console.log(response.data.reasons)
		})
		.catch((error) => {
		  console.log(error);
		});


		data = JSON.stringify({
		  "perPage": 1000,
		  "page": 1,
		  "excludeBanned":true
		});
		config = {
		  method: 'post',
		  maxBodyLength: Infinity,
		  url: 'https://api.athlopro.gr/v1/admin/users',
		  headers: { 
		    'Accept': 'application/json', 
		    'Content-Type': 'application/json', 
		    'Authorization': 'Bearer '+cookies.access_token
		  },
		  data : data
		};

		axios.request(config)
		.then((response) => {
		  setUsersList(response.data.users);
		})
		.catch((error) => {
		  console.log(error);
		});

	},[])
	useEffect(()=>{
		let data = {
		  "perPage": perPage,
		  "page": currentPage,
		};
		let config = {
		  method: 'post',
		  maxBodyLength: Infinity,
		  url: 'https://api.athlopro.gr/v1/admin/getBannedList',
		  headers: { 
		    'Accept': 'application/json', 
		    'Authorization': 'Bearer '+ cookies.access_token,
		  },
		  data : data
		};

		axios.request(config)
		.then((response) => {
			setBannedUsers(response.data.users);
			console.log(response.data.users);
			setTotalPages(response.data.total_pages);
		})
		.catch((error) => {
		  	console.log(error);
		});
	},[currentPage, perPage])

	const handlePageChange = (newPage) => {
	    setCurrentPage(newPage);
	};

	const handlePerPageChange = (e) => {
    	setPerPage(e.target.value);
    	setCurrentPage(1); 
  	};

  const handleAddClick = () => {
  	setIsAddModalOpen(true);
  }

  const handleAddOnHide = () =>{
  	setIsAddModalOpen(false);
  }
  const handleAddOnSave = (input) =>{
  	let data = input;
  	let config = {
		  method: 'post',
		  maxBodyLength: Infinity,
		  url: 'https://api.athlopro.gr/v1/admin/ban',
		  headers: { 
		    'Accept': 'application/json', 
		    'Content-Type': 'application/json', 
		    'Authorization': 'Bearer '+cookies.access_token
		  },
		  data : data
		};

		axios.request(config)
		.then((response) => {
			// console.log(response.data);
		  setBannedUsers([...bannedUsers,response.data.user]);
		  setIsAddModalOpen(false);
		})
		.catch((error) => {
		  console.log(error);
		});
  }

  const startEditing = (id) => {
	  setEditingId(id);
	};

	const finishEditing = () => {
	  setEditingId(null);
	};

	return( 
	<Container>
	    <Row>
	    <AddModal
        show={isAddModalOpen}
        onHide={handleAddOnHide}
        onSave={handleAddOnSave}
        fieldNames={["user_id:required","reason_id:required"]}
        selectLists={{'user':usersList,'reason':reasonsList}}
      />
				<Col>
    			<h2>Banned Users</h2>
    			<button onClick={handleAddClick} disabled={editingId!==null}>Add new +</button>
    		</Col>
    		<Col>
	    		<Form.Group controlId="perPageSelect" className="d-flex align-items-center perPageSelect justify-content-end">
            <Form.Label>Show per page:</Form.Label>
            <Form.Control as="select" value={perPage} onChange={handlePerPageChange}>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={250}>250</option>
              <option value={500}>500</option>
            </Form.Control>
        	</Form.Group>
       	</Col>
      </Row>
	    <Row>
	        <Col>
	          <table className="table">
	            <thead>
	              <tr>
	                <th>User</th>
	                <th>Reason</th>
	                <th>Action</th>
	              </tr>
	            </thead>
	            <tbody>
	              {bannedUsers.map((banned) => (
                	<SingleBanned 
                	key={banned.id} 
                	banned={banned} 
                	reasonsList={reasonsList}
                	currentEdit={editingId === banned.id}
	                editingSibling={editingId!==null}
	                startEditing={startEditing}
	                finishEditing={finishEditing} />
             		))}
	            </tbody>
	          </table>
	        </Col>
	    </Row>
	    <Pagination>
	        <Pagination.Prev
	          onClick={() => handlePageChange(currentPage - 1)}
	          disabled={currentPage === 1}
	        />
	        <Pagination.Item>{currentPage}</Pagination.Item>
	        <Pagination.Next
	          onClick={() => handlePageChange(currentPage + 1)}
	          disabled={currentPage === totalPages}
	        />
      	</Pagination>
	</Container>
	    )
}

export default Banned;