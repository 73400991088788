import React, {useState, useEffect} from 'react';
import {useCookies} from 'react-cookie';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faSave, faUndo } from '@fortawesome/free-solid-svg-icons'
import classes from './ViewUserModal.module.css'
import axios from 'axios';

const save = <FontAwesomeIcon icon={faSave} />
const undo = <FontAwesomeIcon icon={faUndo} />
const pencil = <FontAwesomeIcon icon={faPencil} />
const ViewUserModal = ({ show, onClose, user, typesList, gendersList, citiesList, regionsList, userUpdate }) => {
  const [cookies] = useCookies(['access_token']);
  const [isEditing, setIsEditing] = useState(false);
  const [editedFields, setEditedFields] = useState(user);
  const [shownValues, setShownValues] = useState(user);

  useEffect(()=>{
    if (shownValues.details===null) {
      setShownValues({
        ...shownValues,
        details: {
          city_id: "",
          city: "",
          gender_id: "",
          gender: "",
          region_id: "",
          region: ""
        }
      })
    }
  },[])
  const handleEditClick = () => {
    setIsEditing(true);
    setEditedFields({
      first_name: shownValues.first_name,
      last_name: shownValues.last_name,
      email: shownValues.email,
      accepted_terms: shownValues.accepted_terms,
      type_id: shownValues.type_id?shownValues.type_id:1,
      type: shownValues.type,
      details: {
        ...shownValues.details, 
        city_id: shownValues.details.city_id?shownValues.details.city_id:1,
        city: shownValues.details.city,
        gender_id: shownValues.details.gender_id?shownValues.details.gender_id:1,
        gender: shownValues.details.gender,
        region_id: shownValues.details.region_id?shownValues.details.region_id:1,
        region: shownValues.details.region,
        phone_number: shownValues.details.phone_number,
      },
    });
  };


const handleSaveClick = () =>{
  const changedFields = {};

  Object.keys(editedFields).forEach((field) => {
    if (field==="details") {

        Object.keys(editedFields.details).forEach((detailsField) => {
          if (editedFields.details[detailsField] !== shownValues.details[detailsField]) {
            changedFields[detailsField] = editedFields.details[detailsField];
          }
        });

    }else {
      if (editedFields[field] !== shownValues[field]) {
        changedFields[field] = editedFields[field];
      }
    }
    
  });
  console.log(changedFields);
  let data = {id: shownValues.id};
  if (Object.keys(changedFields).length > 0) {
    data = {
      id: shownValues.id,
      ...changedFields,
    };
  };

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.athlopro.gr/v1/admin/addOrUpdateUser',
      headers: { 
        'Accept': 'application/json', 
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+cookies.access_token
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      setShownValues(response.data.user)
      setIsEditing(false);
      userUpdate({user:response.data.user});
    })
    .catch((error) => {
      console.log(error);
    });

};



const handleUndoClick = () =>{
  setIsEditing(false);
}

const handleFieldChange = (field, value) => {
  if (field === 'first_name' || field === 'last_name' || field === 'email') {
    value = value.slice(0, 255); 
  }
  if (field === 'details.phone_number') {
    value = value.replace(/[^0-9]/g, '').slice(0, 11);
  }
  // setEditedFields((prevFields) => ({ ...prevFields, [field]: value }));
  setEditedFields((prevFields) => {
    // Create a copy of the previous state
    const updatedFields = { ...prevFields };

    // If the field is inside the 'details' object
    if (field.startsWith('details.')) {
      const detailsField = field.split('.')[1]; // Extract the nested field name
      updatedFields.details = {
        ...prevFields.details,
        [detailsField]: value,
      };
    } else {
      // If not a nested field, update directly
      updatedFields[field] = value;
    }

    return updatedFields;
  });
};



const handleOnHide = () => {
  setIsEditing(false);
  onClose();
}

  return (
    <Modal className={classes.Modal} show={show} onHide={handleOnHide}>
      <Modal.Header closeButton>
        <Modal.Title>User Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Col xs={6}>
            <strong>First Name:</strong>
          </Col>
          <Col xs={6}>
            {isEditing ? (
              <input
                type="text"
                value={editedFields.first_name}
                onChange={(e) => handleFieldChange('first_name', e.target.value)}
                style={{ width: '100%' }}
              />
            ) : (
              shownValues.first_name
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={6}>
            <strong>Last Name:</strong>
          </Col>
          <Col xs={6}>
            {isEditing ? (
              <input
                type="text"
                value={editedFields.last_name}
                onChange={(e) => handleFieldChange('last_name', e.target.value)}
                style={{ width: '100%' }}
              />
            ) : (
              shownValues.last_name
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={6}>
            <strong>Email:</strong>
          </Col>
          <Col xs={6}>
            {isEditing ? (
              <input
                type="text"
                value={editedFields.email}
                onChange={(e) => handleFieldChange('email', e.target.value)}
                style={{ width: '100%' }}
              />
            ) : (
              shownValues.email
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={6}>
            <strong>Accepted Terms:</strong>
          </Col>
          <Col xs={6}>
            {isEditing ? (
              <input
                type="checkbox"
                checked={editedFields.accepted_terms === 1}
                onChange={(e) => handleFieldChange('accepted_terms', e.target.checked ? 1 : 0)}
              />
            ) : (
              shownValues.accepted_terms===1?"Yes":"No"
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={6}>
            <strong>Type:</strong>
          </Col>
          <Col xs={6}>
            {isEditing ? (
              <select value={editedFields.type_id?editedFields.type_id:1} onChange={(e) => handleFieldChange('type_id', Number(e.target.value))}>
              {typesList.map((type) => (
                <option key={type.id} value={type.id}>
                  {`${type.name}`}
                </option>
              ))}
              </select>
            ) : (
              `${shownValues.type!==null?shownValues.type:""}`
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={6}>
            <strong>Date of Birth:</strong>
          </Col>
          <Col xs={6}>
            {isEditing ? (
              <input
                type="date"
                value={editedFields.details.date_of_birth}
                onChange={(e) => handleFieldChange('details.date_of_birth', e.target.value)}
                style={{ width: '100%' }}
              />
            ) : (
              `${shownValues.details?(shownValues.details.date_of_birth?shownValues.details.date_of_birth:""):""}`
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={6}>
            <strong>Gender:</strong>
          </Col>
          <Col xs={6}>
            {isEditing ? (
              <select value={editedFields.details.gender_id?editedFields.details.gender_id:1} onChange={(e) => handleFieldChange('details.gender_id', Number(e.target.value))}>
              {gendersList.map((gender) => (
                <option key={gender.id} value={gender.id}>
                  {`${gender.name}`}
                </option>
              ))}
              </select>
            ) : (
              `${shownValues.details?(shownValues.details.gender!==null?shownValues.details.gender.name:""):""}`
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={6}>
            <strong>City:</strong>
          </Col>
          <Col xs={6}>
            {isEditing ? (
              <select value={editedFields.details.city_id?editedFields.details.city_id:1} onChange={(e) => handleFieldChange('details.city_id', Number(e.target.value))}>
              {citiesList.map((city) => (
                <option key={city.id} value={city.id}>
                  {`${city.name}`}
                </option>
              ))}
              </select>
            ) : (
              `${shownValues.details?(shownValues.details.city!==null?shownValues.details.city.name:""):""}`
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={6}>
            <strong>Region:</strong>
          </Col>
          <Col xs={6}>
            {isEditing ? (
              <select value={editedFields.details.region_id?editedFields.details.region_id:1} onChange={(e) => handleFieldChange('details.region_id', Number(e.target.value))}>
              {regionsList.map((region) => (
                <option key={region.id} value={region.id}>
                  {`${region.name}`}
                </option>
              ))}
              </select>
            ) : (
              `${shownValues.details?(shownValues.details.region!==null?shownValues.details.region.name:""):""}`
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={6}>
            <strong>Phone Number:</strong>
          </Col>
          <Col xs={6}>
            {isEditing ? (
              <input
                type="text"
                value={editedFields.details.phone_number}
                onChange={(e) => handleFieldChange('details.phone_number', e.target.value)}
                style={{ width: '100%' }}
              />
            ) : (
              `${shownValues.details?(shownValues.details.phone_number?shownValues.details.phone_number:""):""}`
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
      {isEditing ? (
          <div className={classes.buttonSet}>
            <button onClick={handleSaveClick}>{save}</button>
            <button onClick={handleUndoClick}>{undo}</button>
          </div>
        ) : (
          <div className={classes.buttonSet}>
            <button onClick={handleEditClick}>{pencil}</button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ViewUserModal;