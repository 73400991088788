import React,{Fragment, useState, useEffect} from 'react';
import {useCookies} from 'react-cookie';
import { Container, Form, Row, Col, Pagination } from 'react-bootstrap';
import SingleUser from './SingleUser';
import axios from 'axios';

const Users = (props) => {
	const [cookies] = useCookies(['access_token']);
	const [users, setUsers] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [perPage, setPerPage] = useState(25);
	const [typesList, setTypesList] = useState([]);
	const [gendersList, setGendersList] = useState([]);
	const [citiesList, setCitiesList] = useState([]);
	const [regionsList, setRegionsList] = useState([]);

	useEffect(()=>{
	  let data = {
	    "perPage": 1000,
	    "page": 1,
	  };
	  let config = {
	    method: 'post',
	    maxBodyLength: Infinity,
	    url: 'https://api.athlopro.gr/v1/admin/accountTypes',
	    headers: { 
	      'Accept': 'application/json', 
	      'Authorization': 'Bearer '+ cookies.access_token,
	    },
	    data : data
	  };

	  axios.request(config)
	  .then((response) => {
	    setTypesList(response.data.account_types);
	  })
	  .catch((error) => {
	      console.log(error);
	  });

	  config = {
	    method: 'post',
	    maxBodyLength: Infinity,
	    url: 'https://api.athlopro.gr/v1/admin/genders',
	    headers: { 
	      'Accept': 'application/json', 
	      'Authorization': 'Bearer '+ cookies.access_token,
	    },
	    data : data
	  };

	  axios.request(config)
	  .then((response) => {
	    setGendersList(response.data.genders);
	  })
	  .catch((error) => {
	      console.log(error);
	  });

	  config = {
	    method: 'post',
	    maxBodyLength: Infinity,
	    url: 'https://api.athlopro.gr/v1/admin/regions',
	    headers: { 
	      'Accept': 'application/json', 
	      'Authorization': 'Bearer '+ cookies.access_token,
	    },
	    data : data
	  };

	  axios.request(config)
	  .then((response) => {
	  	setRegionsList(response.data.regions);
	  })
	  .catch((error) => {
	    	console.log(error);
	  });

	  config = {
	    method: 'post',
	    maxBodyLength: Infinity,
	    url: 'https://api.athlopro.gr/v1/admin/cities',
	    headers: { 
	      'Accept': 'application/json', 
	      'Authorization': 'Bearer '+ cookies.access_token,
	    },
	    data : data
	  };

	  axios.request(config)
	  .then((response) => {
	  	setCitiesList(response.data.cities);
	  })
	  .catch((error) => {
	    	console.log(error);
	  });
	},[])

	useEffect(()=>{
		let data = {
		  "perPage": perPage,
		  "page": currentPage,
		};
		let config = {
		  method: 'post',
		  maxBodyLength: Infinity,
		  url: 'https://api.athlopro.gr/v1/admin/users',
		  headers: { 
		    'Accept': 'application/json', 
		    'Authorization': 'Bearer '+ cookies.access_token,
		  },
		  data:data
		};

		axios.request(config)
		.then((response) => {
			setUsers(response.data.users);
			console.log(response.data.users);
			setTotalPages(response.data.total_pages);
		})
		.catch((error) => {
		  	console.log(error);
		});
	},[currentPage, perPage])

	const handlePageChange = (newPage) => {
	    setCurrentPage(newPage);
	};

	const handlePerPageChange = (e) => {
    	setPerPage(e.target.value);
    	setCurrentPage(1); 
  	};

	return( 
	<Container>
	    <h2>Users</h2>
	    <hr/>
	    <Form.Group controlId="perPageSelect" className="d-flex align-items-center perPageSelect justify-content-end">
            <Form.Label>Show per page:</Form.Label>
            <Form.Control as="select" value={perPage} onChange={handlePerPageChange}>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={250}>250</option>
              <option value={500}>500</option>
            </Form.Control>
        </Form.Group>
	    <Row>
	        <Col>
	          <table className="table">
	            <thead>
	              <tr>
	                <th>First Name</th>
	                <th>Last Name</th>
	                <th>Email</th>
	                <th>Accepted Terms</th>
	                <th>Type</th>
	                <th>Action</th>
	              </tr>
	            </thead>
	            <tbody>
	              {users.map((user) => (
                	<SingleUser 
	                	key={user.id} 
	                	user={user} 
	                	typesList={typesList}
	                	gendersList={gendersList}
	                	citiesList={citiesList}
	                	regionsList={regionsList}
                	/>
             		))}
	            </tbody>
	          </table>
	        </Col>
	    </Row>
	    <Pagination>
	        <Pagination.Prev
	          onClick={() => handlePageChange(currentPage - 1)}
	          disabled={currentPage === 1}
	        />
	        <Pagination.Item>{currentPage}</Pagination.Item>
	        <Pagination.Next
	          onClick={() => handlePageChange(currentPage + 1)}
	          disabled={currentPage === totalPages}
	        />
      	</Pagination>
	</Container>
	    )
}

export default Users;