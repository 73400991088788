import React,{Fragment, useState, useEffect} from 'react';
import {useCookies} from 'react-cookie';
import { Container, Row, Col, Nav, Tab } from 'react-bootstrap';
import Championships from './Championships';
import ChampionshipCategories from './ChampionshipCategories';
import ChampionshipPlacements from './ChampionshipPlacements';
import axios from 'axios';

const ChampionshipTabs = (props) => {
	const [key, setKey] = useState('championships');
	return(
			<Container>
				<Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
					<Row>
		          		<Col>
				        	<Nav className="nav-tabs">
		    	              <Nav.Item>
		    	                <Nav.Link eventKey="championships" className="nav-link">Championships</Nav.Link>
		    	              </Nav.Item>
		    	              <Nav.Item>
		    	                <Nav.Link eventKey="categories" className="nav-link">Championship Categories</Nav.Link>
		    	              </Nav.Item>
		    	              <Nav.Item>
		    	                <Nav.Link eventKey="placements" className="nav-link">Championship Placements</Nav.Link>
		    	              </Nav.Item>
		    	            </Nav>
		    	        </Col>
		    	    </Row>
		    		<Row>
		    		    <Col>
		    		        <Tab.Content>
		    		        	<Tab.Pane eventKey="championships" className="tab-pane">
		    		        		<Championships/>
								</Tab.Pane>
								<Tab.Pane eventKey="categories" className="tab-pane">
		    		        		<ChampionshipCategories/>
								</Tab.Pane>
								<Tab.Pane eventKey="placements" className="tab-pane">
		    		        		<ChampionshipPlacements/>
								</Tab.Pane>
							</Tab.Content>
						</Col>
					</Row>
			    </Tab.Container>
			</Container>
		)
	
}

export default ChampionshipTabs;