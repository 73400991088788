import React, { useState } from 'react';
import {useCookies} from 'react-cookie';
import { Navigate, useNavigate } from "react-router-dom";
import classes from './UserLogin.module.css'
import axios from 'axios';

// Login component
const UserLogin = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['access_token']);
  const navigate = useNavigate();
  // Inputs
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Show Errors
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');

  
  // Function to handle form submission

  const handleEmailChange = (e) => {
	    setEmail(e.target.value);

	    // Validate email format
	    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	    const isValidEmail = emailRegex.test(e.target.value);

	    if (!isValidEmail) {
	      setEmailError('Invalid email format');
	    } else {
	      setEmailError('');
	    }
  }
  const handleLogin = (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError('Please enter both email and password.');
      return;
    }

  //1234!@#$

    if (!emailError){
    	let data = JSON.stringify({
	  "email": email,
	  "password": password,
	  "gen_key": "{7K004bk=2d!Wt7A}6a)2=t)NiqPd?l2",
	  "lang": "el"
	});

	let config = {
	  method: 'post',
	  maxBodyLength: Infinity,
	  url: 'https://api.athlopro.gr/v1/login',
	  headers: { 
	    'Accept': 'application/json', 
	    'Content-Type': 'application/json'
	  },
	  data : data
	};

	axios.request(config)
	.then((response) => {
	  console.log(JSON.stringify(response.data));
	  if (response.data.status){
	  	setCookie('access_token', response.data.access_token, { sameSite: 'None',  secure: true, expires: 0 });
	  	navigate('/');
	  }else{
	  	setError('Your email or password was incorrect');
	  }
	  	})
	.catch((error) => {
	  setError('There has been a problem while attempting to login, please try again.');
	});
  }else{
  	setError(emailError)
  }
	
  };

  return (
    <div className={classes.container}>
    	<div className={classes.login}>
    		<img src="/logo192.png" alt="Logo" className={classes.logo} />
	      <form onSubmit={handleLogin} className={classes.form}>
	        <div>
	          <label>Email:</label>
	          <input
	            type="text"
	            value={email}
	            onChange={handleEmailChange}
	          />
	        </div>
	        <div>
	          <label>Password:</label>
	          <input
	            type="password"
	            value={password}
	            onChange={(e) => setPassword(e.target.value)}
	          />
	        </div>
	        <button className={classes.submit} type="submit">Login</button>
	      </form>
	      {error && <p style={{ color: 'red' }}>{error}</p>}
	    </div>
    </div>
  );
};

export default UserLogin;