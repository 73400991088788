import React,{Fragment, useState, useEffect} from 'react';
import {useCookies} from 'react-cookie';
import { Container, Form, Row, Col, Pagination } from 'react-bootstrap';
import SingleReport from './SingleReport';
import axios from 'axios';

const Reports = (props) => {
	const [cookies] = useCookies(['access_token']);
	const [reports, setReports] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
  	const [totalPages, setTotalPages] = useState(1);
  	const [perPage, setPerPage] = useState(25);
	useEffect(()=>{
		let data = {
		  "perPage": perPage,
		  "page": currentPage,
		};
		let config = {
		  method: 'post',
		  maxBodyLength: Infinity,
		  url: 'https://api.athlopro.gr/v1/admin/reports',
		  headers: { 
		    'Accept': 'application/json', 
		    'Authorization': 'Bearer '+ cookies.access_token,
		  },
		  data:data
		};

		axios.request(config)
		.then((response) => {
			setReports(response.data.reports)
			setTotalPages(response.data.total_pages);
		})
		.catch((error) => {
		  	console.log(error);
		});
	},[currentPage, perPage])

	const handlePageChange = (newPage) => {
	    setCurrentPage(newPage);
	};

	const handlePerPageChange = (e) => {
    	setPerPage(e.target.value);
    	setCurrentPage(1); 
  	};
	return( 
	<Container>
	    <h2>Reports</h2>
	    <hr/>
	    <Form.Group controlId="perPageSelect" className="d-flex align-items-center perPageSelect justify-content-end">
	        <Form.Label>Show per page:</Form.Label>
	        <Form.Control as="select" value={perPage} onChange={handlePerPageChange}>
	          <option value={25}>25</option>
	          <option value={50}>50</option>
	          <option value={100}>100</option>
	          <option value={250}>250</option>
	          <option value={500}>500</option>
	        </Form.Control>
	    </Form.Group>
	    <Row>
	        <Col>
	          <table className="table">
	            <thead>
	              <tr>
	                <th>User</th>
	                <th>Reported User</th>
	                <th>Reason</th>
	                <th>Description</th>
	              </tr>
	            </thead>
	            <tbody>
	              {reports.map((report) => (
                	<SingleReport key={report.id} report={report} />
             		))}
	            </tbody>
	          </table>
	        </Col>
	    </Row>
	    <Pagination>
	        <Pagination.Prev
	          onClick={() => handlePageChange(currentPage - 1)}
	          disabled={currentPage === 1}
	        />
	        <Pagination.Item>{currentPage}</Pagination.Item>
	        <Pagination.Next
	          onClick={() => handlePageChange(currentPage + 1)}
	          disabled={currentPage === totalPages}
	        />
      </Pagination>
	</Container>
	    )
}

export default Reports;