import React, {useState, useEffect, Fragment} from 'react';
import {useCookies} from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEye } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import classes from './SingleUser.module.css'
import ConfirmationModal from '../UI/ConfirmationModal'
import ViewUserModal from './ViewUserModal'

const view = <FontAwesomeIcon icon={faEye} />
const trash = <FontAwesomeIcon icon={faTrash} />

const User = ({ user, typesList, gendersList, citiesList, regionsList }) => {
  const [cookies] = useCookies(['access_token']);
  const [userDetails, setUserDetails]=useState();
  const [isViewModalOpen, setIsViewModalOpen]=useState(false);
  const [isModalOpen, setIsModalOpen]=useState(false);
  const [deleted, setDeleted] = useState(false);
  const [singleUser, setSingleUser] = useState(user);


const handleViewClick = () =>{
  setIsViewModalOpen(true);
}

const handleViewClose = () =>{
  setIsViewModalOpen(false);
}

const handleUserUpdate = (update) =>{
  setSingleUser(update.user);
}

const handleDeleteClick = () => {
  setIsModalOpen(true);
}
const handleConfirmDelete = () => {
  let data = {
    id: user.id,
    delete: true,
  };

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://api.athlopro.gr/v1/admin/addOrUpdateUser',
    headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json', 
      'Authorization': 'Bearer '+cookies.access_token
    },
    data : data
  };

  axios.request(config)
  .then((response) => {
    setIsModalOpen(false);
    setDeleted(true);
  })
  .catch((error) => {
    console.log(error);
  });  
}

const handleCancelDelete = () =>{
  setIsModalOpen(false);  
}
  return (
    <Fragment>
    {!deleted ? (
    <tr key={singleUser.id}>
    <ConfirmationModal
        show={isModalOpen}
        onNo={handleCancelDelete}
        onYes={handleConfirmDelete}
      />
      <ViewUserModal
        show={isViewModalOpen}
        onClose={handleViewClose}
        user={singleUser}
        typesList={typesList}
        gendersList={gendersList}
        citiesList={citiesList}
        regionsList={regionsList}
        userUpdate={handleUserUpdate}
      />
      <td>{singleUser.first_name}</td>
      <td>{singleUser.last_name}</td>
      <td>{singleUser.email}</td>
      <td>{singleUser.accepted_terms?'Yes':'No'}</td>
      <td>{singleUser.type_id} - {singleUser.type}</td>
      <td>
        <div className={classes.buttonSet}>
          <button onClick={handleViewClick}>{view}</button>
          <button onClick={handleDeleteClick}>{trash}</button>
        </div>
      </td>
    </tr>
    ) : (
    <Fragment></Fragment>
    )}
    </Fragment>
  );
};

export default User;