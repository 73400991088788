import React,{Fragment, useState, useEffect} from 'react';
import {useCookies} from 'react-cookie';
import { Container, Form, Row, Col, Pagination } from 'react-bootstrap';
import SingleChampionshipCategory from './SingleChampionshipCategory';
import axios from 'axios';

const ChampionshipCategories = (props) => {
	const [cookies] = useCookies(['access_token']);
	const [championshipCategories, setChampionshipCategories] = useState([]);
	const [editingId, setEditingId] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
  	const [totalPages, setTotalPages] = useState(1);
  	const [perPage, setPerPage] = useState(25);

	useEffect(()=>{
		let data = {
		  "perPage": perPage,
		  "page": currentPage,
		};
		let config = {
		  method: 'post',
		  maxBodyLength: Infinity,
		  url: 'https://api.athlopro.gr/v1/admin/championshipCategories',
		  headers: { 
		    'Accept': 'application/json', 
		    'Authorization': 'Bearer '+cookies.access_token,
		  },
		  data:data
		};

		axios.request(config)
		.then((response) => {
			setChampionshipCategories(response.data.championship_categories);
		 	setTotalPages(response.data.total_pages);
		})
		.catch((error) => {
		  console.log(error);
		});
	},[currentPage, perPage])

	const handlePageChange = (newPage) => {
	    setCurrentPage(newPage);
	};

	const handlePerPageChange = (e) => {
    	setPerPage(e.target.value);
    	setCurrentPage(1); 
  	};

  	const startEditing = (id) => {
	  setEditingId(id);
	};

	const finishEditing = () => {
	  setEditingId(null);
	};
	return(
			<Container>
				<Row>
					<Col>
		    			<h2>Championship Categories</h2>
		    		</Col>
		    		<Col>
			    		<Form.Group controlId="perPageSelect" className="d-flex align-items-center perPageSelect justify-content-end">
				            <Form.Label>Show per page:</Form.Label>
				            <Form.Control as="select" value={perPage} onChange={handlePerPageChange}>
				              <option value={25}>25</option>
				              <option value={50}>50</option>
				              <option value={100}>100</option>
				              <option value={250}>250</option>
				              <option value={500}>500</option>
				            </Form.Control>
		        		</Form.Group>
		       		</Col>
	        	</Row>
	        	<hr/>
			    <Row>
			        <Col>
			          <table className="table">
			            <thead>
			              <tr>
			                <th>Name</th>
			                <th>Active</th>
			                <th>Actions</th>
			              </tr>
			            </thead>
			            <tbody>
			              {championshipCategories.map((category) => (
			                <SingleChampionshipCategory 
			                key={category.id} 
			                category={category} 
			                currentEdit={editingId === category.id}
			                editingSibling={editingId!==null}
			                startEditing={startEditing}
			                finishEditing={finishEditing}/>
			              ))}
			            </tbody>
			          </table>
			        </Col>
			    </Row>
			    <Pagination>
			        <Pagination.Prev
			          onClick={() => handlePageChange(currentPage - 1)}
			          disabled={currentPage === 1}
			        />
			        <Pagination.Item>{currentPage}</Pagination.Item>
			        <Pagination.Next
			          onClick={() => handlePageChange(currentPage + 1)}
			          disabled={currentPage === totalPages}
			        />
		      	</Pagination>
			</Container>
						
		)
}

export default ChampionshipCategories;